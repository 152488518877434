/*= スマホとタブレットでviewportを切替え=*/
$(function(){
    var ua = navigator.userAgent;
    if((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)){
        $('head').prepend('<meta name="viewport" content="width=device-width,initial-scale=1">');
    } else {
        $('head').prepend('<meta name="viewport" content="width=1240">');
    } 
});

/*タブレットの時はメニュー:hoverのアニメを停止*/
$(function () {
    var ua = navigator.userAgent;
    if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
        // スマートフォン用コード
    } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
        // タブレット用コード
        $('.menu_list_item').removeClass('menu_anime-swipe');
    } else {
        // PC用コード
    }
});

$(function () {
var ua = navigator.userAgent;
if((ua.indexOf('Android') > 0 && ua.indexOf('Mobile') == -1)){
 $('.js_andr>a').css('pointer-events','none');
}
});


/*=Telリンク=*/
//画像にtellink
$(function(){
    var ua = navigator.userAgent;
    if(ua.indexOf('iPhone') > 0 && ua.indexOf('iPod') == -1 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 && ua.indexOf('SC-01C') == -1 && ua.indexOf('A1_07') == -1 ){
        $('.tellink img').each(function(){
            var alt = $(this).attr('alt');
            $(this).wrap($('<a>').attr('href', 'tel:' + alt.replace(/-/g, '')));
        });
    }
});

//テキストにtellink
$(function(){
    var ua = navigator.userAgent;
    if(ua.indexOf('iPhone') > 0 && ua.indexOf('iPod') == -1 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 && ua.indexOf('SC-01C') == -1 && ua.indexOf('A1_07') == -1 ){
        $('.txt_tellink').each(function(){
            var str = $(this).text();
            $(this).html($('<a>').attr('href', 'tel:' + str.replace(/-/g, '')).append(str + '</a>'));
            //$(this).css('text-decoration', 'underline');
        });
    	
    }
});


//tellink削除（PC）
$(function(){
    var ua = navigator.userAgent;
    if( ua.indexOf('iPhone') < 0 && ua.indexOf('Android') < 0){
        $('a[href^="tel:"]').css( 'cursor', 'default' ).click(function(event){
            //event.preventDefault();
            return false;
        });
    }
});

$(function () {
    /*=スマホメニューの開閉=*/
    var menu = $('.spmenu'),
        menuBtn = $('.menu_btn'),
        body = $(document.body),
        menuWidth = menu.outerWidth(),
        modal = $('.modalwrap'),
        menuheight = menu.outerHeight() ;
    
        menu.css('height', menuheight);
        menuBtn.on('click', function () {
        menu.toggleClass('menuopen');
        menuBtn.toggleClass('open');
        modal.hide();
        $(function () {
            setTimeout(function () {}, 250);
        });
    });　

    //でんわばんごうモーダル    
    var modalBtn = $('.js-modal_on'),
        modal = $('.modalwrap');
    modalBtn.on('click', function () {
        modal.fadeToggle();
    });
    $('.modalwrap').on('click touchend', function (event) {
        if (!$(event.target).closest('.modal_tel').length || $(event.target).closest('.js-modal_off').length) {
            modal.fadeOut();
        }
    });
    $('.js-modal_off').on('click', function () {
        modal.fadeOut();
    });


    /*スライドtoggle*/
    $(".js_st").on("click", function () {
        $(this).next().slideToggle(300, 'swing');
        //$(".select_defo").toggleClass('kaiten')
    });

    var whoge = $(window).width();
    var smaho = 768;

//    /*高さ揃え(jquery.matchHeight)*/
//    //$("[class^='l-col-']").matchHeight();
//    if (whoge < smaho) {
//        $('.js_mh').matchHeight();
//    } else {
//        $('.js_mhpc').matchHeight();
//    }

    /* 画像 PC/SP切り替え */
    // 置換の対象とするclass属性。
    var $imgpcsp = $('.js_imgpcsp');
    // 置換の対象とするsrc属性の末尾の文字列。
    var sp = '_sp.';
    var pc = '_pc.';

    function imgSwitch() {
        $imgpcsp.each(function () {
            var $this = $(this);
            // ウィンドウサイズが768px以上であれば_spを_pcに置換する。
            if (whoge > smaho) {
                $this.attr('src', $this.attr('src').replace(sp, pc));
                // ウィンドウサイズが768px未満であれば_pcを_spに置換する。
            } else {
                $this.attr('src', $this.attr('src').replace(pc, sp));
            }
        });
    }

    imgSwitch();

    var resizeTimer;
    $(window).on('resize', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            imgSwitch();
        }, 200);
    });


/*= TOPにもどる =*/
$(document).ready(function () {
    var pagetop = $('.top_btn');
    pagetop.css('display', 'none');
    $(window).scroll(function () {
        if ($(this).scrollTop() > 0) {
            pagetop //.stop(false,true)
                .fadeIn();
            return false;
        } else {
            pagetop //.stop(false,true)
                .fadeOut();
            return false;
        }
        
    });
});

//mainの高さ確保    
$(document).ready(function () {
var whoge = $(window).width();
var smaho = 768    
var pageHeight = $(document).height();
var vh = $(window).height();
var header = $('header').outerHeight();
var footer = $('footer').outerHeight();
var main = $('main').outerHeight();    
var hugahuga = header + main + footer;    
var minheight = vh - hugahuga;    
if (whoge > smaho ){    
$('main').css('min-height', minheight);
}
else{
    var hugahuga = hugahuga - 85;
    var minheight = vh - hugahuga;
    //console.log(hugahuga);
$('main').css('min-height', minheight);
}

});
    
    
$(window).scroll(function () {
    var pageHeight = $(document).height(); 
    var scrollPosition = $(window).height() + $(window).scrollTop(); 
    var footHeight = 100; 

    var whoge = $(window).width();
    var smaho = 768;

    if (pageHeight - scrollPosition <= footHeight && whoge > smaho) {
        
        $(".top_btn").css({
            "position": "absolute",
            "bottom": "90px"
        });
        
        $(".reform-body .top_btn").css({
            "position": "absolute",
            "bottom": "0"
        });        
    } else if (whoge > smaho){
            $(".top_btn").css({
            "position": "fixed",
            "bottom": "0px"
        });
    }
else {

    }
    
});
    


$(function () {
    // #で始まるアンカーをクリックした場合に処理
    $("a[href^='#']").click(function () {

        var speed = 1000; // ミリ秒
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        //var height = $('header').outerHeight(true);
        var height = 128; //ヘッダーの高さ
        var whoge = $(window).width();
        var smaho = 768;
        var loginheight = 0;

        if (whoge < smaho) {
        var height = 60;
        } else {}
        
        if(whoge < smaho && $('#wpadminbar').length) {
            var height = 106;
        } else {}

        //alert(height);
        var position = target.offset().top - height;
        $('body,html').animate({
            scrollTop: position
        }, speed, 'easeOutCubic');
        return false;

    });
});


    
    
/*= 画面リサイズ（スマホ縦横変更など）で1回だけリロード =*/
//処理が重ければ外す
//$(window).on('orientationchange', function () {
//    var currentWidth = window.innerWidth;
//    if (currentWidth = window.innerWidth) {
//        setTimeout(function () {
//            location.reload();
//        }, 200);
//    } else {
//
//    }
//});
    
    
    });


//$(function () {
// $(".js_nolinksp").on("click", function () {
//    event.preventDefault();
//    //return false;
//    });
//    });  

$(function () {
$('.js_submenu').click(function() {
        $(this).find(".submenu").slideToggle(300, 'easeInOutSine');
        $(this).toggleClass('icon-more');
        $(this).toggleClass('icon-minus');
    });
    });

//親要素への伝播ストップ
$(function () {
$('.submenu-list a').click(function (event) {
event.stopPropagation();
});
});  




$(window).scroll(function () {
    var fixnav = $('.fixnav');
    var h1_height = $('.js_hd').outerHeight(true);
    var keyword_height = $('.keyword').outerHeight(true);
    var header_height = h1_height + keyword_height;
    var whoge = $(window).width();
    var smaho = 768;
    var pagetop = $('.top_btn');
    var pageHeight = $(document).height();
    var scrollPosition = $(window).height() + $(window).scrollTop();
    var footHeight = 90;

    /*=ヘッダー固定=*/
    if ($(this).scrollTop() > header_height && whoge > smaho) {
        fixnav.addClass('js_fixnav');
    } else {
        fixnav.removeClass('js_fixnav');
    }
    /*= TOPにもどる =*/
    if ($(this).scrollTop() > 0) {
        pagetop //.stop(false,true)
            .fadeIn();
        return false;
    } else {
        pagetop //.stop(false,true)
            .fadeOut();
        return false;
    }

});

$(function () {
if($('#wpadminbar').length) {
    $('header,.spmenu').addClass('login');
    } else {}
});

//header隠す
//$(function(){
//  //変数初期化
//  var pos = 0;
//  //スクロールが発生した際に実行
//  $(window).on('scroll', function(){
//    //ユーザーのスクロール位置取得　& スクロール方向で分岐
//    if($(this).scrollTop() < pos || $(this).scrollTop() < 60){
//      //上スクロール時の処理を記述
//        $('header').removeClass('hide');            
//    }else{
//      //下スクロール時の処理を記述
//    $('header').addClass('hide');       
//    }
//    //スクロールが停止した位置を保持
//    pos = $(this).scrollTop();
//  });
//});

$(function(){
var mql = window.matchMedia('screen and (min-width: 768px)');      
var startPos = 0;
var winScrollTop = 0;      
$(window).on("scroll", function () {
if (!mql.matches){    
  winScrollTop = $(this).scrollTop(), startPos <= winScrollTop ? 60 < winScrollTop && $("header,.spmenu").addClass("hide") : $("header,.spmenu").removeClass("hide"), startPos = winScrollTop
    }else{}
});
});

//$(function(){ 
//    
////::::::::::::::    
//// IE11
////::::::::::::::    
//if(navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./)) {
//  $('body').on("mousewheel", function (){
//    // デフォルトの設定値をOFFに切り替える
//    event.preventDefault();
//    var wd = event.wheelDelta;
//    var csp = window.pageYOffset;
//    window.scrollTo(0, csp - wd);
//  });
//}
//var printCheck = $('.js_print').css('display') == 'block' //印刷状態をチェック    
//if(!printCheck){  
//    var fadeoffset = $(window).innerHeight() * 0.25;   
//    var controller = new ScrollMagic.Controller();
//    var fadeBottom = $('.js_fadein');
//    for(var i = 0; i < fadeBottom.length;i++){
//        var tagfadebtm = fadeBottom[i];
//        var tween3;
//        tween3 = TweenMax.fromTo(tagfadebtm, 0.65, {
//                y: "30px",
//                opacity: 0
//            }, // left 0、opacity 0の状態を指定
//            {
//                y: 0,
//                opacity: 1
//            } // left 100px、opacity 1まで変化させる
//        );
//
//
//        var fadebottom = new ScrollMagic.Scene({
//            triggerElement:tagfadebtm,
//            triggerHook: 'onEnter',
//            offset:fadeoffset,
//            reverse:false  
//        })        
//        .setTween(tween3)  
//        .addTo(controller)
//        //.addIndicators({name:"fadein"});
//    }    
//    
//}
//});


/*もっと読むボタンでも高さ揃え*/
//$(function(){
//    var whoge = $(window).width();
//    var smaho = 768;
//    var nextBtn = $('#next_button');
//nextBtn.on('click', function () {
//    /*高さ揃え(jquery.matchHeight)*/
//    if (whoge < smaho) {
//         setTimeout(function(){
//        $('.js_mh').matchHeight();
//                },50);
//    } else {
//         setTimeout(function(){
//        $('.js_mhpc').matchHeight();
//             },500);
//    }
//    });
//        });

